import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { SendEmailService } from '../send-email.service';

@Component({
  selector: 'tsu-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss']
})
export class ContactUsComponent implements OnInit {
  tileForm: FormGroup;
  PropertyItems: any;
  constructor(private contactformTile: SendEmailService,
    private fb: FormBuilder,
    private toastrService: ToastrService,
    private router: Router, private route: Router, private httpClient: HttpClient) { }

  ngOnInit() {
    this.initializeForm();
    this.getPropertyItems();
  }

  private getPropertyItems() {
    this.httpClient.get("assets/json/property.json").subscribe(data => {
      this.PropertyItems = data;
    })
  }

  resetForm() {
    this.toastrService.success(
      "Thanks for sending email. We will contact you soon!",
      "Success"
    );
  }


  sendEmail(): void {
    if (this.tileForm.valid && this.tileForm.dirty) {
      this.toastrService.info(
        "Form submit is in progress",
        "Wait"
      );
      this.tileForm.disable();
      this.contactformTile
        .sendEmail(this.tileForm.value)
        .subscribe(response => {
          if (response) {
            this.tileForm.reset();
            console.error(response);
          } else {
            this.toastrService.success(
              "Thanks for sending email. We will contact you soon!",
              "Success"
            );
            this.tileForm.enable();
            this.tileForm.reset();
          }
        });
    } else {
      this.toastrService.error(
        "Please add all required details in the Contact Form!",
        "Failed"
      );
    }
  }

  private initializeForm(): void {
    this.tileForm = this.fb.group({
      name: ["", Validators.required],
      email: ["", [Validators.email, Validators.required]],
      city: [""],
      number: [""],
      messsage: ["", Validators.required],
      toMail: ["shailesh@singhai.co.in"]
    });
  }
}
