import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';

@Component({
  selector: 'tsu-slider',
  templateUrl: './slider.component.html',
  styleUrls: ['./slider.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SliderComponent implements OnInit {
  customOptions: OwlOptions = {
    loop: true,
    center:true,
    margin: 0,
    nav: false,
    autoplay: true,
    autoplayHoverPause:false,
    mouseDrag:false,
    dots: true,
    responsive: {
      0: {
        items: 1,
      },
      600: {
        items: 1,
      },
      778: {
        items: 1,
      },
      1024: {
        items: 1,
      },
      1200: {
        items: 1,
      }
    }
  };
  constructor() { }

  ngOnInit() {
  }

}
