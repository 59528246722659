import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { NgbCarouselModule } from "@ng-bootstrap/ng-bootstrap";
import { LoadingBarModule } from "@ngx-loading-bar/core";
import { LoadingBarRouterModule } from "@ngx-loading-bar/router";
import { HttpClientModule } from "@angular/common/http";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { CarouselModule } from "ngx-owl-carousel-o";
import { MaterialModule } from "./material/material.module";
import { BannerComponent } from "./banner/banner.component";
import { BoxComponent } from "../services/box/box.component";
import { ContactUsComponent } from "../contact-us/contact-us.component";

@NgModule({
  declarations: [
    BannerComponent,
    BoxComponent,
    ContactUsComponent
  ],
  imports: [
    CommonModule,
    NgbCarouselModule,
    CarouselModule,
    LoadingBarModule,
    LoadingBarRouterModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
  ],
  exports: [
    CommonModule,
    NgbCarouselModule,
    CarouselModule,
    LoadingBarModule,
    LoadingBarRouterModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    MaterialModule,
    BannerComponent,
    BoxComponent,
    ContactUsComponent
  ]
})
export class SharedModuleModule {}
