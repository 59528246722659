import { Injectable } from "@angular/core";
import {
  HttpHeaders,
  HttpErrorResponse,
  HttpClient
} from "@angular/common/http";
import { Observable, throwError } from "rxjs";

const httpOptions = {
  headers: new HttpHeaders({
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Methods": "*",
    "Resonse-Type": "text"
  })
};

interface ObjEmail {
  name: string;
  email?: string;
  subject?: string;
  number?: string;
  message: string;
  city?: string;
  occupation?: string;
  age?: string;
}

const headers1 = new HttpHeaders();
headers1.append("Access-Control-Allow-Headers", "Origin, Content-Type, X-Auth-Token");
headers1.append("Access-Control-Allow-Methods", "POST");
headers1.append("Access-Control-Allow-Origin", "*, http://api.autocomp.in");
@Injectable({
  providedIn: "root"
})
export class SendEmailService {
  constructor(private http: HttpClient) { }
  webApiURL = "https://mail.circleink.in/api/mail";

  sendEmail(hero: ObjEmail): Observable<ObjEmail> {
    return this.http.post<ObjEmail>(
      this.webApiURL, JSON.parse(JSON.stringify(hero)), { headers: headers1 }
    );
  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error("An error occurred:", error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` + `body was: ${error.error}`
      );
    }
    // return an observable with a user-facing error message
    return throwError("Something bad happened; please try again later.");
  }
}
