import { BrowserModule } from "@angular/platform-browser";
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import { NgModule } from "@angular/core";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { HomeComponent } from './home/home.component';
import { ErrorComponent } from './error/error.component';
import { SliderComponent } from './slider/slider.component';
import { HttpClientModule } from "@angular/common/http";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { SlideshowModule } from "ng-simple-slideshow";
import { ToastrModule } from "ngx-toastr";
import { SharedModuleModule } from "./shared-module/shared-module.module";

@NgModule({
  declarations: [AppComponent, HeaderComponent, FooterComponent, HomeComponent, ErrorComponent, SliderComponent],
  imports: [BrowserModule, AppRoutingModule, SharedModuleModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    SlideshowModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModuleModule,
    ToastrModule.forRoot()],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule {}
