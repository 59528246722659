import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'tsu-box',
  templateUrl: './box.component.html',
  styleUrls: ['./box.component.scss']
})
export class BoxComponent implements OnInit {
  @Input() title = 'DINING';
  @Input() description = 'Crafting homes for seniors, fostering community and comfort inevery residence.';
  @Input() bgImage = 'assets/images/services/img-1.jpg';
  constructor() { }

  ngOnInit() {
  }

}
