import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'tsu-banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.scss']
})
export class BannerComponent implements OnInit {
  @Input() title = "";
  @Input() subTitle = "";
  @Input() bgImage = "";
  constructor() { }

  ngOnInit() {
  }

}
